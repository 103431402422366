import decode from 'jwt-decode';

const linkableCustomerIdClaimName = 'https://lendi/linkableCustomerId';
const connectionClaimName = 'https://lendi/connection';

type Jwt = { [key: string]: string } | undefined;
const decodeToken = (token: string | undefined): Jwt => (token ? decode(token) : undefined);

export function isLinkableSso(token: string | undefined): boolean {
  const jwt = decodeToken(token);
  const linkableCustomerId = jwt?.[linkableCustomerIdClaimName];
  const connection = jwt?.[connectionClaimName];
  return !!(linkableCustomerId && connection === 'google-oauth2');
}

export function getLinkableCustomerId(token: string | undefined): string | undefined {
  return decodeToken(token)?.[linkableCustomerIdClaimName];
}
