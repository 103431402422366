import { LALAAuthError } from './state';

export const SENDING_CODE = 'SENDING_CODE';
export const CODE_SENT = 'CODE_SENT';
export const VERIFYING_CODE = 'VERIFYING_CODE';
export const AUTH_ME_ENDED = 'AUTH_ME_ENDED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const SAVE_LEAD_ID = 'SAVE_LEAD_ID';
export const SESSION_EXPIRED = 'SESSION_EXPIRED';
export const RESET_SEND_CODE = 'RESET_SEND_CODE';
export const RESET_ERROR = 'RESET_ERROR';
export const RESET_AUTH_STATE = 'RESET_AUTH_STATE';
export const EXPIRED_SENDING_CODE = 'EXPIRED_SENDING_CODE';
export const AUTHORISE_STARTED = 'AUTHORISE_STARTED';

interface SaveLeadIdAction {
  type: typeof SAVE_LEAD_ID;
  leadId: string;
}

interface VerifyingCodeAction {
  type: typeof VERIFYING_CODE;
  email: string;
}

interface SendingCodeAction {
  type: typeof SENDING_CODE;
  phoneNumber: string;
  firstName: string | undefined;
}

interface CodeSentAction {
  type: typeof CODE_SENT;
}

interface AuthErrorAction {
  type: typeof AUTH_ERROR;
  error: LALAAuthError;
}

interface SessionExpiredAction {
  type: typeof SESSION_EXPIRED;
  firstName?: string;
  phoneNumber?: string;
}

interface ResetErrorAction {
  type: typeof RESET_ERROR;
}

interface ResetSendCodeAction {
  type: typeof RESET_SEND_CODE;
}

interface ResetAuthStateAction {
  type: typeof RESET_AUTH_STATE;
}

interface AuthMeEndedAction {
  type: typeof AUTH_ME_ENDED;
  error?: LALAAuthError | undefined;
}

interface AuthoriseStartedAction {
  type: typeof AUTHORISE_STARTED;
}

export type LALACustomerAppAction =
  | VerifyingCodeAction
  | SendingCodeAction
  | CodeSentAction
  | AuthErrorAction
  | SessionExpiredAction
  | AuthMeEndedAction
  | ResetErrorAction
  | ResetAuthStateAction
  | SaveLeadIdAction
  | AuthoriseStartedAction
  | ResetSendCodeAction;
