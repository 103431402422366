// Prerouted + Referral Ownership Change confluence : https://creditandfinance.atlassian.net/wiki/spaces/SUB/pages/3271689303/Prerouted+Referral+Ownership+Change
// 1 means no change, 2 means broker or store has changed.

enum BrokerStoreValueType {
  OriginalValue = 1,
  NewValue = 2,
}

export interface Rule {
  id: number;
  input: {
    brokerA?: BrokerStoreValueType.OriginalValue;
    brokerB?: BrokerStoreValueType.OriginalValue | BrokerStoreValueType.NewValue;
    storeA?: BrokerStoreValueType.OriginalValue;
    storeB?: BrokerStoreValueType.OriginalValue | BrokerStoreValueType.NewValue;
  };
  output: {
    store?: BrokerStoreValueType.NewValue;
    broker?: BrokerStoreValueType.NewValue;
    message1?: string;
    message2?: string;
    title?: string;
  };
}

export const rules: Rule[] = [
  {
    id: 1,
    input: {
      brokerB: BrokerStoreValueType.NewValue,
    },
    output: {
      broker: BrokerStoreValueType.NewValue,
    },
  },
  {
    id: 2,
    input: {
      storeB: BrokerStoreValueType.NewValue,
    },
    output: {
      store: BrokerStoreValueType.NewValue,
    },
  },
  {
    id: 3,
    input: {
      storeB: BrokerStoreValueType.NewValue,
      brokerB: BrokerStoreValueType.NewValue,
    },
    output: {
      broker: BrokerStoreValueType.NewValue,
      store: BrokerStoreValueType.NewValue,
    },
  },
  {
    id: 4,
    input: {},
    output: {},
  },
  {
    id: 7,
    input: {
      brokerA: BrokerStoreValueType.OriginalValue,
      brokerB: BrokerStoreValueType.NewValue,
    },
    output: {
      broker: BrokerStoreValueType.NewValue,
      message1: 'Your existing broker is {BrokerA}.',
      message2: 'Would you like to update your broker to {BrokerB}, or would you like to keep working with {BrokerA}?',
      title: 'Update assigned broker',
    },
  },
  {
    id: 8,
    input: {
      brokerA: BrokerStoreValueType.OriginalValue,
      storeB: BrokerStoreValueType.NewValue,
      brokerB: BrokerStoreValueType.NewValue,
    },
    output: {
      store: BrokerStoreValueType.NewValue,
      broker: BrokerStoreValueType.NewValue,
      message1: 'Your existing broker is {BrokerA}.',
      message2:
        'Would you like to update your broker to {BrokerB} from {StoreB}, or would you like to keep working with {BrokerA}?',
      title: 'Update assigned broker',
    },
  },
  {
    id: 9,
    input: {
      storeA: BrokerStoreValueType.OriginalValue,
      brokerA: BrokerStoreValueType.OriginalValue,
      brokerB: BrokerStoreValueType.NewValue,
    },
    output: {
      broker: BrokerStoreValueType.NewValue,
      message1: 'Your existing broker is {BrokerA} from {StoreA}.',
      message2: 'Would you like to update your broker to {BrokerB}, or would you like to keep working with {BrokerA}?',
      title: 'Update assigned broker',
    },
  },
  {
    id: 10,
    input: {
      storeA: BrokerStoreValueType.OriginalValue,
      brokerA: BrokerStoreValueType.OriginalValue,
      storeB: BrokerStoreValueType.NewValue,
      brokerB: BrokerStoreValueType.NewValue,
    },
    output: {
      store: BrokerStoreValueType.NewValue,
      broker: BrokerStoreValueType.NewValue,
      message1: 'Your existing broker is {BrokerA} from {StoreA}.',
      message2:
        'Would you like to update your broker to {BrokerB} from {StoreB}, or would you like to keep working with {BrokerA}?',
      title: 'Update assigned broker',
    },
  },
  {
    id: 11,
    input: {
      brokerA: BrokerStoreValueType.OriginalValue,
      brokerB: BrokerStoreValueType.OriginalValue,
    },
    output: {},
  },
  {
    id: 12,
    input: {
      storeA: BrokerStoreValueType.OriginalValue,
      brokerA: BrokerStoreValueType.OriginalValue,
      storeB: BrokerStoreValueType.OriginalValue,
      brokerB: BrokerStoreValueType.OriginalValue,
    },
    output: {},
  },
  {
    id: 13,
    input: {
      storeA: BrokerStoreValueType.OriginalValue,
      brokerA: BrokerStoreValueType.OriginalValue,
      brokerB: BrokerStoreValueType.OriginalValue,
    },
    output: {},
  },
  {
    id: 14,
    input: {
      brokerA: BrokerStoreValueType.OriginalValue,
      storeB: BrokerStoreValueType.OriginalValue,
      brokerB: BrokerStoreValueType.OriginalValue,
    },
    output: {},
  },
  {
    id: 15,
    input: {
      storeA: BrokerStoreValueType.OriginalValue,
      brokerA: BrokerStoreValueType.OriginalValue,
      storeB: BrokerStoreValueType.NewValue,
      brokerB: BrokerStoreValueType.OriginalValue,
    },
    output: {
      store: BrokerStoreValueType.NewValue,
      broker: BrokerStoreValueType.NewValue,
      message1: 'Your existing broker is {BrokerA} from {StoreA}.',
      message2:
        'Would you like to update your broker to {BrokerA} from {StoreB}, or would you like to keep working with {StoreA}?',
      title: 'Update assigned store',
    },
  },
  {
    id: 16,
    input: {
      storeA: BrokerStoreValueType.OriginalValue,
      brokerA: BrokerStoreValueType.OriginalValue,
      storeB: BrokerStoreValueType.NewValue,
    },
    output: {
      store: BrokerStoreValueType.NewValue,
      message1: 'Your existing broker is {BrokerA} from {StoreA}.',
      message2: 'Would you like to update your store to {StoreB}, or would you like to keep working with {BrokerA}?',
      title: 'Update assigned store',
    },
  },
  {
    id: 17,
    input: {
      brokerA: BrokerStoreValueType.OriginalValue,
      storeB: BrokerStoreValueType.NewValue,
    },
    output: {
      store: BrokerStoreValueType.NewValue,
      message1: 'Your existing broker is {BrokerA}.',
      message2: 'Would you like to update to {StoreB}, or would you like to keep working with {BrokerA}?',
      title: 'Update assigned store',
    },
  },
  {
    id: 18,
    input: {
      brokerA: BrokerStoreValueType.OriginalValue,
      storeA: BrokerStoreValueType.OriginalValue,
      storeB: BrokerStoreValueType.OriginalValue,
    },
    output: {},
  },
  {
    id: 19,
    input: {
      brokerA: BrokerStoreValueType.OriginalValue,
      storeA: BrokerStoreValueType.OriginalValue,
      storeB: BrokerStoreValueType.OriginalValue,
      brokerB: BrokerStoreValueType.NewValue,
    },
    output: {
      store: BrokerStoreValueType.NewValue,
      broker: BrokerStoreValueType.NewValue,
      message1: 'Your existing broker is {BrokerA} from {StoreA}.',
      message2:
        'Would you like to update your broker to {BrokerB} {StoreA} or would you like to keep working with {BrokerA}?',
      title: 'Update assigned broker',
    },
  },
  {
    id: 23,
    input: {
      storeA: BrokerStoreValueType.OriginalValue,
      storeB: BrokerStoreValueType.OriginalValue,
      brokerB: BrokerStoreValueType.NewValue,
    },
    output: {
      store: BrokerStoreValueType.NewValue,
      broker: BrokerStoreValueType.NewValue,
      message1: 'Your existing store is {StoreA}.',
      message2:
        'Would you like to update your broker to {StoreA} {BrokerB}, or would you like to keep working with Aussie {StoreA}?',
      title: 'Update assigned broker',
    },
  },
  {
    id: 24,
    input: {
      storeA: BrokerStoreValueType.OriginalValue,
      storeB: BrokerStoreValueType.NewValue,
      brokerB: BrokerStoreValueType.NewValue,
    },
    output: {
      store: BrokerStoreValueType.NewValue,
      broker: BrokerStoreValueType.NewValue,
      message1: 'Your existing store is {StoreA}.',
      message2:
        'Would you like to update your broker to {StoreB} {BrokerB}, or would you like to keep working with Aussie {StoreA}?',
      title: 'Update assigned broker',
    },
  },
  {
    id: 25,
    input: {
      storeA: BrokerStoreValueType.OriginalValue,
      storeB: BrokerStoreValueType.OriginalValue,
    },
    output: {
      store: BrokerStoreValueType.NewValue,
    },
  },
  {
    id: 26,
    input: {
      storeA: BrokerStoreValueType.OriginalValue,
      storeB: BrokerStoreValueType.NewValue,
    },
    output: {
      store: BrokerStoreValueType.NewValue,
      message1: 'Your existing store is {StoreA}.',
      message2: 'Would you like to update to {StoreB}, or would you like to keep working with Aussie {StoreA}?',
      title: 'Update assigned store',
    },
  },
  {
    id: 27,
    input: {
      storeA: BrokerStoreValueType.OriginalValue,
      brokerB: BrokerStoreValueType.NewValue,
    },
    output: {
      broker: BrokerStoreValueType.NewValue,
      message1: 'Your existing store is {StoreA}.',
      message2:
        'Would you like to update your broker to {BrokerB}, or would you like to keep working with Aussie {StoreA}?',
      title: 'Update assigned broker',
    },
  },
];
