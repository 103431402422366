import { Brand } from '@lendi/lala-utils';
import * as React from 'react';

export const TRANSLATION_KEY_ARR = [
  'SITE_NAME',
  'PRIVACY_POLICY_LINK',
  'SIGN_IN_TITLE',
  'SIGN_IN_SUBTITLE',
  'TIME_SELECTION_CONFIRMATION_TITLE',
  'TIME_SELECTION_CONFIRMATION_SUBTITLE',
  'BOOK_APPOINTMENT_TITLE',
  'BOOK_APPOINTMENT_SUBTITLE',
  'BOOK_APPOINTMENT_BUYERS_AGENT_TITLE',
  'BOOK_APPOINTMENT_BUYERS_AGENT_SUBTITLE',
  'STAMP_DUTY_TITLE',
  'STAMP_DUTY_SUBTITLE',
  'PROPERTY_REPORT_TITLE',
  'PROPERTY_REPORT_SUBTITLE',
  'CREDIT_SCORE_TITLE',
  'CREDIT_SCORE_SUBTITLE',
  'SAFETY_NET_TITLE',
  'SAFETY_NET_SUBTITLE',
  'RETURNED_SSO_TITLE',
  'RETURNED_SSO_SUBTITLE',
  'REFERRAL_SUBTITLE',
] as const;

export type TRANSLATION_KEY = typeof TRANSLATION_KEY_ARR[number];
export interface TranslationOptions {
  text: string;
  requireParse: boolean;
}

export interface Translation {
  [Brand.Lendi]: {
    [key in TRANSLATION_KEY]: string | TranslationOptions | undefined;
  };
  [Brand.Aussie]: {
    [key in TRANSLATION_KEY]: string | TranslationOptions;
  };
}

export interface TranslationProviderProps {
  brand: Brand;
  children: React.ReactNode;
}
