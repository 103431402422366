import { AuthOrigin } from '@lendi/core-constants';
import { Brand, LalaSentry, getAPIBaseURL, getBrandFromHostname, getEnv, BYPASS_POSTCODE } from '@lendi/lala-utils';
import { LendiGroupLeads, Origin } from '@lendi/lendigroup-leads-library';

export interface LeadData {
  authOrigin: AuthOrigin;
  mobileNumber?: string;
  firstName?: string;
  customerId?: string;
  brand?: Brand;
  email?: string;
  utm?: {
    utmSource: string;
  };
}

export async function sendLeadsData(data: LeadData) {
  try {
    const brand = data.brand || getBrandFromHostname();
    const baseURL = getAPIBaseURL(getEnv());
    const leadData = LendiGroupLeads.retrieveLatestLeadData();
    const isLendiGroupLead = leadData?.data?.version === 'v3';
    LalaSentry.logInfo('Sending lead!', { isLendiGroupLead });
    const params = new URLSearchParams(window.location.search);
    const storeId = params.get('storeid');
    const brokerId = params.get('brokerid');
    const refCode = params.get('refcode');

    localStorage.setItem(BYPASS_POSTCODE, storeId || brokerId ? 'true' : 'false');

    const isDefaultLendiGroupLeads = leadData.funnelName === Origin.SignUp && leadData.data.timestamp === 0;
    await LendiGroupLeads.sendLatestLead(baseURL, {
      ...data,
      brand,
      firstName: data.firstName || leadData.data.firstName,
      ...(isDefaultLendiGroupLeads && { accountOrigin: window.location.href }),
      ...(refCode && { utm: { utmSource: 'CustomerReferral' } }),
    });
  } catch (error) {
    LalaSentry.logError('Posting lead data failed!', { error });
  }
}
