export const trimPhoneNumber = (phoneNumber: string) => phoneNumber.split(' ').join('');

export const formatInitialPhoneNumber = (phoneNumber: string) => phoneNumber.replace(/(\+61)?0?(4\d{8})/, '$2');

export const maskPhoneNumber = (phoneNumber: string): string =>
  phoneNumber
    .split('')
    .map((phone, index) => (index >= 1 && index <= 4 ? 'X' : phone))
    .join('');

const maskMiddle = (val: string = '') =>
  val.length < 3 ? ''.padEnd(val.length, '*') : val[0] + ''.padEnd(val.length - 2, '*') + val[val.length - 1];

export const maskEmail = (email: string = ''): string => {
  const [account, domain] = email.split('@');
  return maskMiddle(account) + '@' + domain;
};

export const formattedPhoneNumber = (phoneNumber: string) =>
  phoneNumber.replace(/(\+61)0?(4\d{2})(\d{3})(\d{3})/, '$1 $2 $3 $4');

export const getFirstNameForFunnel = (): string | undefined => {
  const funnelSessionStorageStr = sessionStorage.getItem('funnel_one_form_values');
  if (!funnelSessionStorageStr) return undefined;
  const funnelSessionStorage = JSON.parse(funnelSessionStorageStr);
  if (funnelSessionStorage.applicants && funnelSessionStorage.applicants[0]) {
    return funnelSessionStorage.applicants[0].firstName;
  }
  return undefined;
};
