export type IdentifyFunc = (userId: string, traits?: any, options?: any) => Promise<any>;

export enum AuthTrackingPageNames {
  SIGN_IN = 'login',
  INVITE = 'invite',
  LEAD = 'lead',
  REGISTER = 'register',
  REGISTER_VERIFY = 'register verify',
  SIGN_IN_OTP = 'login verify',
  BAA_SIGN_IN = 'Book Appointment Login',
  BAA_BUYERS_AGENT_SIGN_IN = 'Book Appointment Buyers Agent Login',
  BAA_SIGN_IN_OTP = 'Book Appointment Login OTP',
  BAA_BUYERS_AGENT_SIGN_IN_OTP = 'Book Appointment Buyers Agent Login OTP',
  STAMP_DUTY_SIGN_IN = 'Stamp duty calculator Login',
  STAMP_DUTY_SIGN_IN_OTP = 'Stamp duty calculator Login OTP',
  PROPERTY_REPORT_SIGN_IN = 'Property report Login',
  REFER_A_FRIND_SIGN_IN = 'Refer a friend Login',
  CREDIT_SCORE_SIGN_IN = 'Credit score Login',
  PROPERTY_REPORT_SIGN_IN_OTP = 'Property report Login OTP',
  SIGN_IN_MARKETING = 'Marketing returned SSO login',
  VERIFY_LEADS = 'Verify leads',
  UNKNOWN = '',
  SIGN_IN_V2 = 'login V2',
  BAA_SIGN_IN_V2 = 'Book Appointment Login v2',
  STAMP_DUTY_SIGN_IN_V2 = 'Stamp duty calculator Login v2',
  PROPERTY_REPORT_SIGN_IN_V2 = 'Property report Login v2',
  SIGN_IN_MARKETING_V2 = 'Marketing returned SSO login v2',
  EMAIL_SAFETY_NET = 'Email Safety net',
  INTL_NOT_RECOGNIZED = 'Not Recognized Intl Number',
  INTL_OTP = 'Intl Verify',
  INTL_SIGN_IN = 'Intl Login',
  POST_CODE = 'Postcode Search',
}

export enum AccountLinkingPageNames {
  PROMPT = 'account-link-prompt',
  SUCCESS = 'account-link-success',
  VERIFY = 'account-link-verify',
}

export interface IdentityTracking {
  trackCustomer: (customerId?: string, flags?: any) => Promise<void>;
}

export interface PageTracking {
  trackAuthPage: (name: AuthTrackingPageNames) => Promise<void>;
  trackAccountLinkingPage: (name: AccountLinkingPageNames) => Promise<void>;
}

export interface EventTracking {
  trackAuthButtonClickEvent: (purpose: string, text: string, buttonType?: string) => Promise<void>;
  trackAuthLinkClickEvent: (text: string, purpose?: string) => Promise<void>;
  trackAuthenticationEvent: () => Promise<void>;
  trackRegisterEvent: (customerId: string, trackingFailedErrorMessage?: string) => Promise<void>;
}
