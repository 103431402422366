import { Brand } from '@lendi/lala-utils';
import { Translation } from './types';

export const Translations: Translation = {
  [Brand.Lendi]: {
    SITE_NAME: 'Lendi',
    TIME_SELECTION_CONFIRMATION_TITLE: {
      text: `To confirm your appointment, <br/>sign in`,
      requireParse: true,
    },
    TIME_SELECTION_CONFIRMATION_SUBTITLE: {
      text: `If you’re new to Lendi, you'll be prompted to <br/>create an account.`,
      requireParse: true,
    },
    BOOK_APPOINTMENT_TITLE: {
      text: `Book an appointment with one of our experts`,
      requireParse: true,
    },
    BOOK_APPOINTMENT_SUBTITLE: {
      text: `We’ll help you find the right home loan for your situation. Sign in below or, if you’re new to Lendi, you'll be prompted to create an account.`,
      requireParse: true,
    },
    STAMP_DUTY_TITLE: {
      text: `View your stamp duty results`,
      requireParse: true,
    },
    STAMP_DUTY_SUBTITLE: {
      text: `Sign in below or, if you’re new to Lendi, you'll be prompted to create an account.`,
      requireParse: true,
    },
    PROPERTY_REPORT_TITLE: {
      text: `View your property report`,
      requireParse: true,
    },
    PROPERTY_REPORT_SUBTITLE: {
      text: `Sign in below to view your report. <br/>New to Lendi? Create an account by entering your number.`,
      requireParse: true,
    },
    CREDIT_SCORE_TITLE: {
      text: 'Create an account with Lendi',
      requireParse: true,
    },
    CREDIT_SCORE_SUBTITLE: {
      text: 'To keep your details and score saved, accessible and secure.',
      requireParse: true,
    },
    REFERRAL_SUBTITLE: {
      text: 'To connect a friend with your trusted Home Loan Specialist',
      requireParse: true,
    },
    PRIVACY_POLICY_LINK: '/privacypolicy/',
    SIGN_IN_TITLE: `Sign in to Lendi`,
    SIGN_IN_SUBTITLE: `To access your application or create a new account, enter your mobile phone number and receive a one-time passcode via SMS.`,
    SAFETY_NET_TITLE: `You're almost there`,
    SAFETY_NET_SUBTITLE: `Lendi will send you a one-time passcode via SMS to access your personalised home loan results.`,
    RETURNED_SSO_TITLE: 'Secure your account',
    RETURNED_SSO_SUBTITLE: `You'll be able to verify with your mobile phone number right after this.`,
    BOOK_APPOINTMENT_BUYERS_AGENT_TITLE: undefined,
    BOOK_APPOINTMENT_BUYERS_AGENT_SUBTITLE: undefined,
  },
  [Brand.Aussie]: {
    SITE_NAME: 'Aussie',
    TIME_SELECTION_CONFIRMATION_TITLE: {
      text: `To confirm your appointment, <br/>sign in`,
      requireParse: true,
    },
    TIME_SELECTION_CONFIRMATION_SUBTITLE: {
      text: `If you’re new to Aussie, you'll be prompted to <br/>create an account.`,
      requireParse: true,
    },
    BOOK_APPOINTMENT_TITLE: {
      text: `Book an appointment with one of our experts`,
      requireParse: true,
    },
    BOOK_APPOINTMENT_SUBTITLE: {
      text: `We’ll help you find the right home loan for your situation. Sign in below or, if you're new to Aussie, you'll be prompted to create an account.`,
      requireParse: true,
    },
    BOOK_APPOINTMENT_BUYERS_AGENT_TITLE: {
      text: `Book an appointment with one of our experts`,
      requireParse: true,
    },
    BOOK_APPOINTMENT_BUYERS_AGENT_SUBTITLE: {
      text: `We’re here to help you make the right choice. Sign in below, or follow the prompts to create an account if it’s your first time with Aussie.`,
      requireParse: true,
    },
    STAMP_DUTY_TITLE: {
      text: `View your stamp duty results`,
      requireParse: true,
    },
    STAMP_DUTY_SUBTITLE: {
      text: `Sign in below or, if you’re new to Aussie, you'll be prompted to create an account.`,
      requireParse: true,
    },
    PROPERTY_REPORT_TITLE: {
      text: `View your property report`,
      requireParse: true,
    },
    PROPERTY_REPORT_SUBTITLE: {
      text: `Sign in below to view your report. <br/>New to Aussie? Create an account by entering your number.`,
      requireParse: true,
    },
    CREDIT_SCORE_TITLE: {
      text: 'Create an account with Aussie',
      requireParse: true,
    },
    CREDIT_SCORE_SUBTITLE: {
      text: 'To keep your details and score saved, accessible and secure.',
      requireParse: true,
    },
    REFERRAL_SUBTITLE: {
      text: 'To refer a friend to your Aussie Broker',
      requireParse: true,
    },
    PRIVACY_POLICY_LINK: '/about-us/privacy/',
    SIGN_IN_TITLE: `Sign in to Aussie`,
    SIGN_IN_SUBTITLE: `Aussie uses a one-time passcode with your Australian mobile number. You will receive a one-time passcode via SMS.`,
    SAFETY_NET_TITLE: `You're almost there`,
    SAFETY_NET_SUBTITLE: `Aussie will send you a one-time passcode via SMS to access your personalised home loan results.`,
    RETURNED_SSO_TITLE: 'Secure your account',
    RETURNED_SSO_SUBTITLE: `You'll be able to verify with your mobile phone number right after this.`,
  },
};
