import * as Cookies from 'js-cookie';
import {
  Connection,
  PasswordlessAuthenticationDetails,
  SessionAuthoriseOptions,
  VerifyCodeOptions,
  Auth0ReturnSSIOptions,
} from '@lendi/lala-react';
import {
  GenericError,
  LalaSentry,
  ErrorCodes,
  STATE_STORAGE_KEY,
  getStateFromStorage,
  AuthenticatedState,
  getBrandFromHostname,
  Brand,
  SAFETY_NET_COOKIE_NAME,
} from '@lendi/lala-utils';
import { DOUBLE_VERIFICATION_EMAIL } from '../constants';
import { Dispatch, useMemo } from 'react';
import { doRedirect } from '../helpers';
import { maskPhoneNumber } from '../helpers/util';
import { LALACustomerAppAction } from './actions';
import { LALAAuthFlow } from './state';
import { eventTracking } from '../helpers/tracking';
import Router from '../helpers/router';
import { getCustomerDetails, mergeAccounts } from '../api/customer';
import { AuthOrigin } from '@lendi/core-constants';
import { sendLeadsData } from './sendLeadsData';
import { handleOwnershipChange } from './handleOwnershipChange';
import { getLinkableCustomerId, isLinkableSso } from './isLinkableSso';

export class LalaCustomerSagas {
  protected _dispatch: Dispatch<LALACustomerAppAction>;
  protected _verify: (options: VerifyCodeOptions) => Promise<string | undefined>;
  protected _send: (phoneNumber: string) => Promise<void>;
  protected _popupAuthorise: (options: SessionAuthoriseOptions) => Promise<Auth0ReturnSSIOptions | undefined>;
  protected _deprecatedAuthReset: () => void;

  constructor(
    dispatch: Dispatch<LALACustomerAppAction>,
    { send, verify }: PasswordlessAuthenticationDetails,
    deprecatedAuthReset: () => void,
    popupAuthorise: (options: SessionAuthoriseOptions) => Promise<Auth0ReturnSSIOptions | undefined>
  ) {
    this._dispatch = dispatch;
    this._send = send;
    this._verify = verify;
    this._popupAuthorise = popupAuthorise;
    this._deprecatedAuthReset = deprecatedAuthReset;
  }

  public expireSession(firstName?: string, phoneNumber?: string) {
    this._dispatch({ type: 'SESSION_EXPIRED', firstName, phoneNumber });
  }

  public resetError() {
    this._dispatch({ type: 'RESET_ERROR' });
  }
  public resetSendCode() {
    this._dispatch({ type: 'RESET_SEND_CODE' });
  }
  public resetAuthState(callback?: () => void) {
    this._dispatch({ type: 'RESET_AUTH_STATE' });
    if (typeof sessionStorage !== 'undefined') {
      sessionStorage.removeItem(STATE_STORAGE_KEY);
    }
    if (callback) callback();
  }

  public sendCode(phoneNumber: string, firstName?: string, callback?: () => void) {
    const state = getStateFromStorage() as AuthenticatedState;

    this._dispatch({ type: 'SENDING_CODE', phoneNumber, firstName });

    if (!state?.identity?.id) {
      sendLeadsData({
        authOrigin: AuthOrigin.Passwordless,
        mobileNumber: phoneNumber,
        firstName,
      });
    }

    this.configureUserContext(phoneNumber);

    this._send(phoneNumber)
      .then(() => {
        this._dispatch({ type: 'CODE_SENT' });
        if (callback) callback();
      })
      .catch((error) => {
        this.captureException(error, 'Auth0CodeSend');
        this._dispatch({ type: 'AUTH_ERROR', error: { authError: error, flow: LALAAuthFlow.Passwordless } });
      });
  }

  protected configureUserContext(phoneNumber: string) {
    LalaSentry.configureUserContext({ phoneNumber: maskPhoneNumber(phoneNumber) });
  }

  protected captureException(error: any, tag: string) {
    LalaSentry.captureException(error, tag);
  }

  public async verifyCode(
    phone: string,
    email: string,
    verificationCode: string,
    ssoMergeFF: boolean,
    leadId?: string,
    firstName?: string
  ) {
    const state = getStateFromStorage() as AuthenticatedState;
    LalaSentry.logInfo('saga.verifyCode', { ssoMergeFF, userId: state?.identity?.id });
    this._dispatch({ type: 'VERIFYING_CODE', email });
    await this._verify({ phone, code: verificationCode, email, leadId, firstName })
      .then(async (token) => {
        await this.verifyCodeSuccess(token!, state, ssoMergeFF);
      })
      .catch((error) => {
        this.captureException(error, 'Auth0CodeVerify');
        this._dispatch({ type: 'AUTH_ERROR', error: { authError: error, flow: LALAAuthFlow.Passwordless } });
      });
  }

  protected clearLeadsSession() {
    const origins = [
      'BORROWING_POWER',
      'PROPERTY_REPORT',
      'TOOLBOX',
      'LAM_FORM',
      'SIGN_UP',
      'NEW_HOME_LOAN',
      'REFINANCE',
      'BLOG',
      // 'STAMP_DUTY_CALCULATOR', -> due to IM-271
      'APPOINTMENT_BOOKING',
      'INVITE',
      'CHAT',
      'SELF_GEN',
    ];

    try {
      origins.forEach((origin) => {
        sessionStorage.removeItem(`l3-${origin}`);
      });
    } catch (error) {
      console.error('Failed to clear the storage', error);
    }
  }

  protected async verifyCodeSuccess(
    accessToken: string,
    preState: AuthenticatedState | undefined,
    ssoMergeFF: boolean
  ) {
    const state = getStateFromStorage() as AuthenticatedState;
    const existingUser = state.identity.existingUser;

    // fire Authenticated event
    await eventTracking.trackAuthenticationEvent(state.identity.connection);

    // clear the session storage for any existing l3 keys
    const brand = getBrandFromHostname();
    if (brand === Brand.Aussie) {
      this.clearLeadsSession();
    }

    Cookies.set(SAFETY_NET_COOKIE_NAME, 'false');
    try {
      const customer = await getCustomerDetails(accessToken);
      if (!existingUser) {
        // fire Registered event
        await eventTracking.trackRegisterEvent(customer.id!, state.identity.connection);
      }

      const params = new URLSearchParams(window.location.search.toLowerCase());
      const isEmailVerify = params.get('returnurl')?.includes('/me/verify-email');

      let next: 'safety-net' | 'verify-sso' | 'email-safety-net' | 'ownership' | undefined = undefined;
      if (customer.state?.emailVerification === 'NEEDED' && !isEmailVerify) {
        sessionStorage.setItem(DOUBLE_VERIFICATION_EMAIL, customer.email || '');
        next = 'email-safety-net';
      } else if (ssoMergeFF) {
        next = await this.getNextAfterConditionalMerge(accessToken, preState);
      }
      next = (await handleOwnershipChange(accessToken, next)) || next;
      if (next) {
        LalaSentry.logInfo('Next on getCustomer done', { userId: state?.identity?.id, next });
        Router.push({ pathname: '/' + next, query: Router.query() });
      } else doRedirect();
    } catch (_err) {
      this._dispatch({
        type: 'AUTH_ME_ENDED',
        error: { authError: new GenericError(), flow: LALAAuthFlow.Passwordless },
      });
      this._deprecatedAuthReset();
    }
  }

  private async getNextAfterConditionalMerge(
    accessToken: string,
    preState: AuthenticatedState | undefined
  ): Promise<'safety-net' | 'verify-sso' | undefined> {
    const currentState = getStateFromStorage() as AuthenticatedState;
    const preToken = (preState as AuthenticatedState)?.token;
    const userId = currentState?.identity?.id;
    const isPreviousTokenLinkableSso = isLinkableSso(preToken);
    LalaSentry.logInfo('Check merge', { isPreviousTokenLinkableSso, userId });
    if (isPreviousTokenLinkableSso) {
      const ssoToken = preToken;
      const otpToken = accessToken;
      try {
        await mergeAccounts(accessToken, ssoToken, otpToken);
      } catch (err) {
        return 'safety-net';
      }
    } else {
      if (getLinkableCustomerId(accessToken)) {
        return 'verify-sso';
      }
    }
    return undefined;
  }

  protected async authoriseSuccess(token: string, _existingCustomer: boolean) {
    try {
      const { brand, mobileNumber, email, firstName, id: customerId } = await getCustomerDetails(token);
      sendLeadsData({
        authOrigin: AuthOrigin.Google,
        brand: (brand as unknown) as Brand,
        mobileNumber,
        email,
        firstName,
        customerId,
      });

      Cookies.set(SAFETY_NET_COOKIE_NAME, mobileNumber?.startsWith('+61') ? 'false' : 'true');
      if (!_existingCustomer) {
        eventTracking.trackRegisterEvent(customerId!, 'google-oauth2');
      }
      eventTracking.trackAuthenticationEvent('google-oauth2');

      LalaSentry.logInfo('SSO lead data sent.');

      let next: 'verify-to-continue' | 'ownership' | undefined;
      if (isLinkableSso(token)) {
        next = 'verify-to-continue';
      }
      next = (await handleOwnershipChange(token, next)) || next;
      if (next) {
        LalaSentry.logInfo('Next on SSO getCustomer done', { next });
        Router.push({ pathname: '/' + next, query: Router.query() });
      } else doRedirect();
    } catch (error) {
      LalaSentry.logError('authoriseSuccess method failed!', { error });
      this._dispatch({ type: 'AUTH_ME_ENDED', error: { authError: new GenericError(), flow: LALAAuthFlow.SSI } });
      this._deprecatedAuthReset();
    }
  }

  public authorise(connection: Connection) {
    this._dispatch({ type: 'AUTHORISE_STARTED' });

    this._popupAuthorise({ connection })
      .then((result) => {
        if (result) this.authoriseSuccess(result.token, result.existingCustomer);
      })
      .catch((error) => {
        if (error.code === ErrorCodes.POPUP_CLOSED) {
          this._dispatch({ type: 'AUTH_ME_ENDED' });
          return;
        }
        this.captureException(error, 'Auth0SSIAuthorise');
        this._dispatch({ type: 'AUTH_ERROR', error: { authError: error, flow: LALAAuthFlow.SSI } });
      });
  }
}

export const useActionsWithAsync = (
  dispatch: Dispatch<LALACustomerAppAction>,
  passwordlessAuthDetails: PasswordlessAuthenticationDetails,
  deprecatedAuthReset: () => void,
  authorisePopup: (options: SessionAuthoriseOptions) => Promise<Auth0ReturnSSIOptions | undefined>
): LalaCustomerSagas =>
  useMemo(() => new LalaCustomerSagas(dispatch, passwordlessAuthDetails, deprecatedAuthReset, authorisePopup), []);
