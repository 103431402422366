import { LalaSentry } from '@lendi/lala-utils';
import { PlatformPathname } from '@lendi/core-constants';
import {
  LinkEvent,
  ButtonType,
  EventCategory,
  BaseEvent,
  AuthenticationStatus,
  CustomerType,
} from '@lendi/analytics-web';
import { EventTracking } from './types';
import { getReferrer } from '..';
import { LALAWebAnalytics } from './WebAnalytics';

export class EventTrackingService extends LALAWebAnalytics implements EventTracking {
  private async tracking<T extends BaseEvent>(event: T, trackingFailedErrorMessage?: string) {
    try {
      await this.instance.trackEvent(event);
    } catch (err) {
      const cannotTrackError = err instanceof Error ? err : new Error('Cannot track event');
      LalaSentry.captureException(
        cannotTrackError,
        trackingFailedErrorMessage || `cannot track event: ${event.event_name}`
      );
    }
  }

  private getFunnelName() {
    const referrer = getReferrer();
    if (!referrer) return undefined;
    const funnelNames = Object.values(PlatformPathname);
    const matchedFunnelName = funnelNames.find((funnelName: string) => {
      referrer.includes(funnelName);
    });
    return matchedFunnelName;
  }

  async trackAuthButtonClickEvent(purpose: string, text: string, buttonType?: string) {
    const event = {
      event_name: 'Button Clicked',
      category: EventCategory.AUTH,
      buttonType: buttonType === 'secondary' ? ButtonType.SECONDARY : ButtonType.PRIMARY,
      text,
      purpose,
    };
    await this.tracking(event);
  }

  async trackAuthSelectEvent(selectedValue: string, title: string) {
    const event = {
      category: EventCategory.AUTH,
      event_name: 'Dropdown Selected',
      selection: selectedValue,
      title: 'Postcode or Suburb',
      customerType: CustomerType.CUSTOMER,
    };
    await this.tracking(event);
  }

  async trackAuthLinkClickEvent(text: string) {
    const event: LinkEvent = {
      event_name: 'Link Clicked',
      category: EventCategory.AUTH,
      linkType: 'Linktopage',
      text,
    };
    await this.tracking(event);
  }

  async trackAuthenticationEvent(connection?: string) {
    const event = {
      event_name: AuthenticationStatus.AUTHENTICATED,
      category: EventCategory.AUTH,
      funnel: this.getFunnelName(),
      authSource: connection,
    };
    await this.tracking(event, EventCategory.AUTH);
  }

  async trackRegisterEvent(customerId: string, connection?: string) {
    const event = {
      event_name: 'Registered',
      user_id: customerId,
      category: EventCategory.AUTH,
      funnel: this.getFunnelName(),
      authSource: connection,
    };
    await this.tracking(event, EventCategory.AUTH);
  }

  async trackPreRoutedEvent(brokerId: string | undefined, storeId: string | undefined) {
    const event = {
      event_name: 'Pre routed Customer',
      category: EventCategory.AUTH,
      brokerId,
      storeId,
    };
    await this.tracking(event, EventCategory.AUTH);
  }
  async trackCustomerReferralEvent(brokerId: string, refCode: string) {
    const event = {
      event_name: 'Customer Referral',
      category: EventCategory.AUTH,
      brokerId,
      refCode,
    };
    await this.tracking(event, EventCategory.AUTH);
  }
}
