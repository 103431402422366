import NextRouter from 'next/router';
import * as URL from 'url';

const addPrefixToPath = (url: URL.UrlObject | string, as?: string | URL.UrlObject) =>
  `${process.env.PATH_PREFIX}${URL.format(as ? as : url)}`;

const Router = {
  push: (url: URL.UrlObject | string, as?: string | URL.UrlObject, options?: {}) => {
    NextRouter.push(url, addPrefixToPath(url, as), options);
  },
  replace: (url: URL.UrlObject | string, as?: string | URL.UrlObject, options?: {}) => {
    NextRouter.replace(url, addPrefixToPath(url, as), options);
  },
  query: () => NextRouter.query,
  pathname: () => NextRouter.pathname,
};

export default Router;
