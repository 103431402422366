import { Status, useSession } from '@lendi/lala-react';
import * as Cookies from 'js-cookie';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import * as React from 'react';
import { v4 } from 'uuid';
import { identityTracking } from '../../helpers/tracking';
import { getCustomerDetails } from '../../api/customer';
const initialState = {
  identifyCalled: false,
  ldReady: false,
};
export const LDContext = React.createContext(initialState);
const LaunchDarklyConsumer: React.FC = ({ children }) => {
  let targetId = Cookies.get('targetId') || v4();
  const hasSSOAccessed = !!Cookies.get('hasSSOAccessed');
  const ldClient = useLDClient();

  const { identity, status, token } = useSession();
  const [identifyCalled, setIdentifyCalled] = React.useState(false);
  const [ldReady, setLDReady] = React.useState(false);
  const { brand, type } = useSession();
  // get LD user from launchDarkly when user is anonymous
  const ldMultiContext = {
    kind: 'multi',
    unauthUser: {
      key: targetId,
      isTeamView: false,
      hasSSOAccessed,
      brand,
    },
  };
  React.useEffect(() => {
    if (ldClient && status !== Status.Authenticated) {
      ldClient.identify(ldMultiContext, undefined, (_error, flags) => {
        identityTracking.trackCustomer('', flags || undefined, { brand, type });
        setIdentifyCalled(true);
      });
    }
  }, [ldClient, status]);

  // get LD user from launchDarkly when user is logged in
  React.useEffect(() => {
    (async () => {
      if (ldClient && status === Status.Authenticated) {
        try {
          // get more details about the users and save them to LD as attributes
          const { authOrigin, emailVerified, mobileVerified, utm } = await getCustomerDetails(token);
          const details = { authOrigin, emailVerified, mobileVerified, ...utm };
          ldClient.identify(
            {
              ...ldMultiContext,
              user: {
                key: identity?.id,
                isTeamView: false,
                hasSSOAccessed,
                brand,
                authOrigin,
                emailVerified,
                mobileVerified,
                ...utm,
              },
            },
            undefined,
            (_error, flags) => {
              identityTracking.trackCustomer(identity?.id, flags, { ...details, brand, type });
              setIdentifyCalled(true);
            }
          );
        } catch (err) {
          ldClient.identify(
            {
              ...ldMultiContext,
              user: {
                key: identity?.id,
                isTeamView: false,
                hasSSOAccessed,
                brand,
              },
            },
            undefined,
            (_error, flags) => {
              identityTracking.trackCustomer('', flags || undefined, { brand, type });
              setIdentifyCalled(true);
            }
          );
        }
      }
    })();
  }, [ldClient, status]);

  React.useEffect(() => {
    const checkLDReady = async () => {
      if (!ldClient) return;
      await ldClient.waitUntilReady();
      setLDReady(true);
    };
    checkLDReady();
  }, [ldClient, identifyCalled]);
  return <LDContext.Provider value={{ identifyCalled, ldReady }}>{children}</LDContext.Provider>;
};
export default LaunchDarklyConsumer;
