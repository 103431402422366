import WebAnalytics from '@lendi/analytics-web';

export class LALAWebAnalytics {
  private readonly lendiAnalytics: WebAnalytics;
  constructor() {
    let writeKey: string;
    const hostname = typeof window !== 'undefined' ? window.location.hostname : '';
    if (hostname.indexOf('aussie') !== -1) {
      writeKey = process.env.NEXT_PUBLIC_AUSSIE_SEGMENT_ID!;
    } else {
      writeKey = process.env.NEXT_PUBLIC_SEGMENT_ID!;
    }
    const lendiAnalytics = new WebAnalytics({ writeKey });
    this.lendiAnalytics = lendiAnalytics;
  }
  get instance() {
    return this.lendiAnalytics;
  }
}
