import { LalaSentry } from '@lendi/lala-utils';
import { IdentityTracking } from './types';
import { CustomerType } from '@lendi/analytics-web';
import { LALAWebAnalytics } from './WebAnalytics';

export class IdentityTrackingService extends LALAWebAnalytics implements IdentityTracking {
  private createTraits() {
    return {
      user_type: CustomerType.CUSTOMER,
    };
  }

  async trackCustomer(customerId?: string, flags?: any, userTraits?: any): Promise<void> {
    try {
      const baseTraits = this.createTraits();
      const traits = { ...baseTraits, ...userTraits };
      await this.instance.identify(customerId || '', flags || {}, traits);
    } catch (err) {
      const cannotIdentifyError = err instanceof Error ? err : new Error('Cannot identify customer');
      LalaSentry.captureException(cannotIdentifyError, `Cannot identify customer: ${customerId}`);
    }
  }
}
