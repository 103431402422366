import * as React from 'react';
import { Brand } from '@lendi/lala-utils';
import { Translations } from './translations';
import { TranslationProviderProps, Translation } from './types';

export const TranslationContext = React.createContext<Translation[Brand.Lendi | Brand.Aussie] | undefined>(undefined);

export const TranslationProvider: React.FC<TranslationProviderProps> = ({ children, brand }) => {
  const getBrandTranslation = () => {
    return Translations[brand];
  };
  const translations = React.useMemo(() => getBrandTranslation(), [brand]);

  return <TranslationContext.Provider value={translations}>{children}</TranslationContext.Provider>;
};
