import { PlatformPathname } from '@lendi/core-constants';
import { Environment } from '@lendi/lala-utils';
import { useEffect, useRef } from 'react';
import { TRANSLATION_KEY } from '../context';

interface SavedCallback {
  current: () => any;
}

/***
 * taken from @dan_abramov
 * https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 */
export function useInterval(callback: () => any, delay: number | null) {
  const savedCallback: SavedCallback = useRef(() => null);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return;
  }, [delay]);
}

export const getEnv: () => Environment = () => {
  const env = process.env.ENVIRONMENT || 'development';
  switch (env) {
    case Environment.Development:
    case Environment.Staging:
    case Environment.Preproduction:
    case Environment.Production:
      return env;
    default:
      throw new Error(`Invalid environment ${env}`);
  }
};

export const getReferrer = () => {
  if (typeof window === 'undefined') {
    return undefined;
  }
  return window.document.referrer;
};

export const mapSignInTranslationKey = (
  referrer: string,
  textKey?: string
): { title: TRANSLATION_KEY; subtitle: TRANSLATION_KEY } => {
  switch (true) {
    case textKey === 'returned':
      return {
        title: 'RETURNED_SSO_TITLE',
        subtitle: 'RETURNED_SSO_SUBTITLE',
      };
    case referrer.includes(PlatformPathname.REFER_A_FRIEND_PATHNAME):
      return {
        title: 'SIGN_IN_TITLE',
        subtitle: 'REFERRAL_SUBTITLE',
      };
    case referrer.includes(PlatformPathname.TIME_SELECTION_PROCESS_PATHNAME):
      return {
        title: 'TIME_SELECTION_CONFIRMATION_TITLE',
        subtitle: 'TIME_SELECTION_CONFIRMATION_SUBTITLE',
      };
    case referrer.includes(PlatformPathname.BOOKING_APPOINTMENT_PATHNAME):
      return {
        title: 'BOOK_APPOINTMENT_TITLE',
        subtitle: 'BOOK_APPOINTMENT_SUBTITLE',
      };
    case referrer.includes(PlatformPathname.BOOKING_APPOINTMENT_BUYERS_AGENT_PATHNAME):
    case referrer.includes(PlatformPathname.BUYERS_AGENT_PATHNAME):
      return {
        title: 'BOOK_APPOINTMENT_BUYERS_AGENT_TITLE',
        subtitle: 'BOOK_APPOINTMENT_BUYERS_AGENT_SUBTITLE',
      };
    case referrer.includes(PlatformPathname.STAMP_DUTY_PATHNAME):
      return {
        title: 'STAMP_DUTY_TITLE',
        subtitle: 'STAMP_DUTY_SUBTITLE',
      };
    case referrer.includes(PlatformPathname.PROPERTY_REPORT_PATHNAME):
      return {
        title: 'PROPERTY_REPORT_TITLE',
        subtitle: 'PROPERTY_REPORT_SUBTITLE',
      };
    case referrer.includes(PlatformPathname.CHECK_YOUR_CREDIT_SCORE_PATHNAME):
    case referrer.includes(PlatformPathname.CREDIT_SCORE_RESULT_PATHNAME):
    case referrer.includes(PlatformPathname.FREE_CREDIT_SCORE_CHECK_PATHNAME):
      return {
        title: 'CREDIT_SCORE_TITLE',
        subtitle: 'CREDIT_SCORE_SUBTITLE',
      };
    default:
      return {
        title: 'SIGN_IN_TITLE',
        subtitle: 'SIGN_IN_SUBTITLE',
      };
  }
};

export { useRedirect } from './useRedirect';
export { doRedirect } from './useRedirect';
