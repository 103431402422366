import { LalaSentry } from '@lendi/lala-utils';
import { getStore, getTeamMember } from '../../api/team';
import { findRule } from './rules/findRule';

export interface OwnershipChangeDecision {
  promptMessage1?: string;
  promptMessage2?: string;
  title?: string;
  requestStoreId?: string;
  requestOwnerId?: string;
}

export interface OwnershipChangeDecisionInput {
  currentOwnerId?: string;
  currentStoreId?: string;
  targetStoreId?: string;
  targetBrokerId?: string;
}

const DoNothingDecision: OwnershipChangeDecision = {};

interface BrokerInfo {
  id: string;
  fullName: string;
}

interface StoreInfo {
  id: string;
  name: string;
}

const brokerActiveStatuses = ['Competent', 'Active', 'Compliance', 'Graduate', 'PIP'];

async function resolveBroker(accessToken: string, id: string | undefined): Promise<BrokerInfo | undefined> {
  if (id) {
    const broker = await getTeamMember(accessToken, id);
    const fullName = `${broker?.firstName || ''} ${broker?.lastName || ''}`.trim();
    if (brokerActiveStatuses.includes(broker?.brokerStatus || '')) {
      return { id, fullName };
    }
  }
  LalaSentry.logInfo('Broker status is not any of brokerActiveStatusese!', {
    BrokerId: id,
  });
  return undefined;
}

async function resolveStore(accessToken: string, id: string | undefined): Promise<StoreInfo | undefined> {
  if (id) {
    const store = await getStore(accessToken, id);
    if (store?.storeStatus === 'ACTIVE') {
      return { id, name: store?.name || '' };
    }
  }
  LalaSentry.logInfo('Store status is not Avtive!', {
    StoreId: id,
  });
  return undefined;
}

function replacePlaceholders(template: string, placeholders: any) {
  return template.replace(/{([^{}]*)}/g, (match: any, key: any) => placeholders[key] || '');
}

export async function decideOwnershipChange(
  accessToken: string,
  { currentOwnerId, targetStoreId, targetBrokerId, currentStoreId }: OwnershipChangeDecisionInput
): Promise<OwnershipChangeDecision> {
  const [currentBroker, currentStore, targetBroker, targetStore] = await Promise.all([
    resolveBroker(accessToken, currentOwnerId),
    resolveStore(accessToken, currentStoreId),
    resolveBroker(accessToken, targetBrokerId),
    resolveStore(accessToken, targetStoreId),
  ]);

  const rule = findRule(currentStore?.id, currentBroker?.id, targetStore?.id, targetBroker?.id);

  if (rule) {
    const placeholders1 = {
      BrokerA: currentBroker?.fullName,
      StoreA: currentStore?.name,
    };

    const placeholders2 = {
      BrokerA: currentBroker?.fullName,
      BrokerB: targetBroker?.fullName,
      StoreA: currentStore?.name,
      StoreB: targetStore?.name,
    };

    const message1 = rule.messageTemplate1 && replacePlaceholders(rule.messageTemplate1, placeholders1);
    const message2 = rule.messageTemplate2 && replacePlaceholders(rule.messageTemplate2, placeholders2);

    return {
      promptMessage1: message1,
      promptMessage2: message2,
      title: rule.title,
      requestStoreId: rule.requestStoreId,
      requestOwnerId: rule.requestOwnerId,
    };
  }

  LalaSentry.logInfo('Could not match with any rule!', {
    currentStore: currentStore?.id,
    currentBroker: currentBroker?.id,
    targetStore: targetStore?.id,
    targetBroker: targetBroker?.id,
  });

  return DoNothingDecision;
}
