import { Session, SessionProvider } from '@lendi/lala-react';
import { getBrandFromHostname, getEnv, LalaSentry, Type } from '@lendi/lala-utils';
import { CommPanelProvider } from '@lendi/navbar';
import { Theme } from '@lendi/ui/Theme';
import App, { AppInitialProps } from 'next/app';
import LaunchDarklyConsumer from '../components/LaunchDarkly';
import { TranslationProvider } from '../context';
import { FunctionalLalaAppInner } from '../layout/appInner';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import * as React from 'react';
import 'react-international-phone/style.css';
import '../components/MobileInput/style.css';

LalaSentry.init();

const session = new Session({
  brand: getBrandFromHostname(),
  type: Type.Customer,
  environment: getEnv(),
});

class LalaApp extends App {
  static async getInitialProps({ Component, ctx }: any): Promise<AppInitialProps> {
    try {
      let pageProps = {};
      if (Component.getInitialProps) {
        pageProps = await Component.getInitialProps(ctx);
      }

      return { pageProps };
    } catch (err) {
      LalaSentry.captureException(err, 'LalaApp', ctx);
      throw err;
    }
  }

  componentDidCatch(error: Error, errorInfo?: React.ErrorInfo) {
    LalaSentry.captureException(error, 'LalaApp', errorInfo);
    // @ts-ignore
    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { Component, pageProps } = this.props;
    /**
     * As part of ThemeV2 & Navbar constraint (use of `window`), the following check enforces Client Side Rendering.
     * This is a temporary measure, and will be removed in the future once SSR is fully supported.
     * @see [LUI-854](https://creditandfinance.atlassian.net/browse/LUI-854)
     */
    if (typeof window === 'undefined') return <></>;
    return (
      <Theme name={session.brand}>
        <SessionProvider session={session}>
          <LaunchDarklyConsumer>
            <FunctionalLalaAppInner>
              <CommPanelProvider>
                <TranslationProvider brand={session.brand}>
                  <Component {...pageProps} />
                </TranslationProvider>
              </CommPanelProvider>
            </FunctionalLalaAppInner>
          </LaunchDarklyConsumer>
        </SessionProvider>
      </Theme>
    );
  }
}

export default withLDProvider({ clientSideID: process.env.LD_KEY || '' })(LalaApp);
