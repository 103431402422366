import { useFlags } from 'launchdarkly-react-client-sdk';
import { Connection } from '@lendi/lala-react';
import * as React from 'react';
import { LalaCustomerSagas } from './sagas';
import { LalaSentry } from '@lendi/lala-utils';

export interface LalaCustomerActions {
  expireSession: (firstName?: string | undefined, phoneNumber?: string | undefined) => void;
  resetError: () => void;
  sendCode: (phoneNumber: string, firstName?: string, callback?: () => void) => void;
  resetSendCode: () => void;
  verifyCode: (phone: string, email: string, verificationCode: string, leadId?: string, firstName?: string) => void;
  resetAuthState: (callback?: () => void) => void;
  authorisePopup: (connection: Connection) => void;
}

export const ActionsContext = React.createContext<LalaCustomerActions | undefined>(undefined);

export const useActions = (): LalaCustomerActions => {
  const context = React.useContext(ActionsContext);

  if (!context) {
    throw new Error('ActionsContext must be used inside LALA Customer App');
  }

  return context;
};

export interface ActionsProviderProps {
  actions: LalaCustomerSagas;
}

export const ActionsProvider: React.FC<ActionsProviderProps> = ({ actions, children }) => {
  const { ssoMerge } = useFlags();
  LalaSentry.logInfo('Loading ActionsProvider', { ssoMerge });
  return (
    <ActionsContext.Provider
      value={{
        sendCode: (phoneNumber: string, firstName?: string, callback?: (() => void) | undefined) =>
          actions.sendCode(phoneNumber, firstName, callback),
        verifyCode: (
          phone: string,
          email: string,
          verificationCode: string,
          leadId?: string | undefined,
          firstName?: string | undefined
        ) => actions.verifyCode(phone, email, verificationCode, ssoMerge, leadId, firstName),
        expireSession: (firstName?: string | undefined, phoneNumber?: string | undefined) =>
          actions.expireSession(firstName, phoneNumber),
        resetError: () => actions.resetError(),
        resetAuthState: (callback?: () => void) => actions.resetAuthState(callback),
        authorisePopup: (connection: Connection) => actions.authorise(connection),
        resetSendCode: () => actions.resetSendCode(),
      }}
    >
      {children}
    </ActionsContext.Provider>
  );
};
