import {
  AUTH_ME_ENDED,
  LALACustomerAppAction,
  RESET_ERROR,
  SAVE_LEAD_ID,
  SENDING_CODE,
  SESSION_EXPIRED,
  VERIFYING_CODE,
  CODE_SENT,
  AUTH_ERROR,
  RESET_AUTH_STATE,
  AUTHORISE_STARTED,
  RESET_SEND_CODE,
} from './actions';
import { LALACustomerAppState, initialState } from './state';

export const reducer = (state: LALACustomerAppState, action: LALACustomerAppAction): LALACustomerAppState => {
  switch (action.type) {
    case AUTH_ERROR:
      return { ...state, loading: false, sendingCode: false, error: action.error };
    case VERIFYING_CODE:
      return { ...state, loading: true, email: action.email, error: undefined };
    case SENDING_CODE:
      return {
        ...state,
        sendingCode: true,
        loading: true,
        phoneNumber: action.phoneNumber,
        firstName: action.firstName || state.firstName,
        error: undefined,
      };
    case CODE_SENT:
      return { ...state, sendingCode: false, loading: false, codeSent: true };
    case RESET_SEND_CODE:
      return { ...state, codeSent: false };
    case SESSION_EXPIRED:
      return {
        ...state,
        existingCustomer: true,
        phoneNumber: action.phoneNumber || state.phoneNumber,
        firstName: action.firstName || state.firstName,
      };
    case SAVE_LEAD_ID:
      return { ...state, leadId: action.leadId };
    case AUTH_ME_ENDED:
      return { ...state, loading: false, error: action.error ? action.error : undefined };
    case RESET_ERROR:
      return { ...state, error: undefined };
    case RESET_AUTH_STATE:
      return initialState;
    case AUTHORISE_STARTED:
      return { ...state, loading: true };
    default:
      return state;
  }
};
