import { LalaSentry } from '@lendi/lala-utils';
import { AuthTrackingPageNames, AccountLinkingPageNames, PageTracking } from './types';
import { EventCategory } from '@lendi/analytics-web';
import { LALAWebAnalytics } from './WebAnalytics';

export class PageTrackingService extends LALAWebAnalytics implements PageTracking {
  private readonly AuthTrackingPageNames = AuthTrackingPageNames;
  private readonly AccountLinkingPageNames = AccountLinkingPageNames;

  get authTrackingPageName() {
    return this.AuthTrackingPageNames;
  }

  get accountLinkingPageNames() {
    return this.AccountLinkingPageNames;
  }

  async trackAuthPage(name: AuthTrackingPageNames): Promise<void> {
    try {
      await this.instance.trackPage(EventCategory.AUTH, name);
    } catch (err) {
      const cannotIdentifyError = err instanceof Error ? err : new Error('Cannot track page');
      LalaSentry.captureException(cannotIdentifyError, `Cannot track page for Category: Auth and Page Name: ${name}`);
    }
  }

  async trackAccountLinkingPage(name: AccountLinkingPageNames): Promise<void> {
    try {
      await this.instance.trackPage(EventCategory.ACCOUNT_LINKING, name);
    } catch (err) {
      const cannotIdentifyError = err instanceof Error ? err : new Error('Cannot track page');
      LalaSentry.captureException(
        cannotIdentifyError,
        `Cannot track page for Category: account-linking and Page Name: ${name}`
      );
    }
  }
}
