import { usePasswordlessAuthentication, useSession } from '@lendi/lala-react';
import * as React from 'react';
import { ActionsProvider } from '../state/actionProvider';
import { reducer } from '../state/reducer';
import { useActionsWithAsync } from '../state/sagas';
import { initialState, LALACustomerAppContext } from '../state/state';

export const FunctionalLalaAppInner: React.FC = ({ children }) => {
  const { DEPRECATED_RESET, popupAuthorise } = useSession();
  const passwordlessAuth = usePasswordlessAuthentication();
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const actions = useActionsWithAsync(dispatch, passwordlessAuth, DEPRECATED_RESET, popupAuthorise);

  return (
    <ActionsProvider actions={actions}>
      <LALACustomerAppContext.Provider value={state}>{children}</LALACustomerAppContext.Provider>
    </ActionsProvider>
  );
};
