import { AuthError } from '@lendi/lala-react';
import { createContext, useContext } from 'react';

export enum LALACustomerAppComponentEnum {
  LOADING,
  REQUEST_CODE,
  VERIFY_CODE,
  EXPIRED,
}

export type LALACustomerAppComponent = keyof typeof LALACustomerAppComponentEnum;
export enum LALAAuthFlow {
  'SSI',
  'Passwordless',
}

export interface LALAAuthError {
  authError: AuthError;
  flow: LALAAuthFlow;
}

export interface LALACustomerAppState {
  leadId: string;
  phoneNumber: string;
  email: string;
  firstName?: string;
  existingCustomer: boolean;
  sendingCode: boolean;
  loading: boolean;
  error: LALAAuthError | undefined;
  codeSent: boolean;
}

export const initialState: LALACustomerAppState = {
  leadId: '',
  phoneNumber: '',
  email: '',
  firstName: undefined,
  existingCustomer: false,
  sendingCode: false,
  loading: false,
  error: undefined,
  codeSent: false,
};

export const LALACustomerAppContext = createContext<LALACustomerAppState | undefined>(undefined);

export const useLALACustomerAppContext = (): LALACustomerAppState => {
  const context = useContext(LALACustomerAppContext);

  if (!context) {
    throw new Error('LALACustomerAppContext must be used inside the LALACustomerApp');
  }

  return context;
};
