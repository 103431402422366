import { getMeFromStorage } from '@lendi/lala-react';
import {
  AuthenticatedState,
  BYPASS_POSTCODE,
  Brand,
  CUSTOMER_REFERRAL_CODE,
  LOCAL_STORAGE_KEYS,
  LalaSentry,
  PRL_BROKER_ID,
  PRL_CAMPAIGN_NAME,
  PRL_COMPANY_GEN,
  PRL_STORE_ID,
  SESSION_STORAGE_KEYS,
  getBrandFromHostname,
  getStateFromStorage,
} from '@lendi/lala-utils';
import { getCustomerDetails, requestOwner, resolveOwner, setAdvocate, updateCustomerRecord } from '../api/customer';
import { eventTracking } from '../helpers/tracking';
import { decideOwnershipChange } from './decideOwnershipChange';

/*
  signedInUser parameter:
    signedInUser parameter is set true when the user is already is signed-in 
    and reaches to ownership-check page, as of now, only from Booking Appointment page.
    signedInUser parameter is falsy/undefined when the user is just signed in.
    the effect of signedInUser parameter are two:
      1- if falsy, NextJs routing could be used, if truthy, browser window.assign
      2- if truthy, use cached owner when noOwnerCacheFF is off, if falsy, always use cached owner
  */
export async function handleOwnershipChange(
  accessToken: string,
  next?: string,
  signedInUser?: boolean,
  noOwnerCacheFF?: boolean
): Promise<'ownership' | undefined> {
  const brand = getBrandFromHostname();
  if (![Brand.Aussie, Brand.Lendi].includes(brand)) return undefined;

  const currentState = getStateFromStorage() as AuthenticatedState;
  const { currentOwnerId, currentStoreId } = await getCurrentOwnership(accessToken, signedInUser, noOwnerCacheFF);
  const userId = currentState?.identity?.id;
  if (!userId) return undefined;

  const newStoreIdFromSession = sessionStorage.getItem(SESSION_STORAGE_KEYS.CTOR_TARGET_STORE_ID) || undefined;
  const newBrokerIdFromSession = sessionStorage.getItem(SESSION_STORAGE_KEYS.CTOR_TARGET_BROKER_ID) || undefined;
  const targetFromSession = !!(newStoreIdFromSession || newBrokerIdFromSession);
  const newStoreId = targetFromSession ? newStoreIdFromSession : localStorage.getItem(PRL_STORE_ID) || undefined;
  const newBrokerId = targetFromSession ? newBrokerIdFromSession : localStorage.getItem(PRL_BROKER_ID) || undefined;
  const customerRefCode = localStorage.getItem(CUSTOMER_REFERRAL_CODE) || undefined;
  const campaignName = localStorage.getItem(PRL_CAMPAIGN_NAME) || undefined;
  const companyGen = localStorage.getItem(PRL_COMPANY_GEN) || undefined;

  // Convert companyGen to a boolean (true if 'true', undefined if not set or 'false')
  const isCompanyGen = companyGen === 'true' ? true : undefined;

  sessionStorage.removeItem(SESSION_STORAGE_KEYS.CTOR_TARGET_STORE_ID);
  sessionStorage.removeItem(SESSION_STORAGE_KEYS.CTOR_TARGET_BROKER_ID);
  localStorage.removeItem(PRL_STORE_ID);
  localStorage.removeItem(PRL_BROKER_ID);
  localStorage.removeItem(CUSTOMER_REFERRAL_CODE);
  localStorage.removeItem(PRL_COMPANY_GEN);
  localStorage.removeItem(PRL_CAMPAIGN_NAME);
  sessionStorage.removeItem(SESSION_STORAGE_KEYS.CTOR_REQUESTED_STORE_ID);
  sessionStorage.removeItem(SESSION_STORAGE_KEYS.CTOR_REQUESTED_BROKER_ID);

  if (customerRefCode) {
    eventTracking.trackCustomerReferralEvent(newBrokerId!, customerRefCode!);
    try {
      await setAdvocate(accessToken, customerRefCode);
    } catch (error) {
      LalaSentry.logError('Something went wrong calling setAdvocate!', { error, customerRefCode });
    }
  }
  let referralOwnerId;
  try {
    referralOwnerId = targetFromSession ? undefined : await getReferralOwner(customerRefCode);
  } catch (error) {
    LalaSentry.logError('Something went wrong calling getReferralOwner!', { error, customerRefCode });
  }

  const decision = await decideOwnershipChange(accessToken, {
    currentOwnerId,
    currentStoreId,
    targetStoreId: newStoreId,
    targetBrokerId: referralOwnerId || newBrokerId,
  });

  const requestOwnerChange = !!decision.requestOwnerId || !!decision.requestStoreId;

  const bypassPostcode = sessionStorage.getItem('postcode_bypass_invite') === 'true' || requestOwnerChange;
  localStorage.setItem(BYPASS_POSTCODE, bypassPostcode ? 'true' : 'false');

  if (!requestOwnerChange) {
    LalaSentry.logInfo('No change of ownership', {
      currentStoreId,
      currentOwnerId,
      referralOwnerId,
      newStoreId,
      newBrokerId,
      userId,
    });
    return undefined;
  }

  // Update customer record with campaignName and selfGen flag, so we can send them when publishing OwnershipChangeRequests
  if (campaignName || isCompanyGen) {
    try {
      await updateCustomerRecord(accessToken, userId, campaignName, isCompanyGen);
    } catch (error) {
      LalaSentry.logError('Something went wrong updating customer record with campaignName and selfGen flag!', {
        error,
        userId,
      });
    }
  }

  eventTracking.trackPreRoutedEvent(decision.requestOwnerId, decision.requestStoreId);

  if (decision.requestStoreId) {
    sessionStorage.setItem(SESSION_STORAGE_KEYS.CTOR_REQUESTED_STORE_ID, decision.requestStoreId);
  }
  if (decision.requestOwnerId) {
    sessionStorage.setItem(SESSION_STORAGE_KEYS.CTOR_REQUESTED_BROKER_ID, decision.requestOwnerId);
  }

  if (!decision.promptMessage1 && !decision.promptMessage2) {
    await requestOwner(accessToken, userId, decision.requestOwnerId, decision.requestStoreId);
    return undefined;
  }

  const data = JSON.stringify({ decision, next, outOfLALA: signedInUser });
  localStorage.setItem(LOCAL_STORAGE_KEYS.COTR_PROMPT_DATA, data);
  return 'ownership';
}

async function getReferralOwner(customerRefCode: string | undefined) {
  if (customerRefCode) {
    const { ownerId } = await resolveOwner(customerRefCode);
    return ownerId || undefined;
  }
  return undefined;
}

async function getCurrentOwnership(accessToken: string, signedInUser?: boolean, noOwnerCacheFF?: boolean) {
  LalaSentry.logInfo('getCurrentOwnership', { signedInUser, noOwnerCacheFF });
  if (signedInUser && noOwnerCacheFF) {
    const customer = await getCustomerDetails(accessToken);
    return { currentOwnerId: customer.ownerId, currentStoreId: customer.storeId };
  }
  const { ownerId: currentOwnerId, storeId: currentStoreId } = getMeFromStorage() || {};
  return { currentOwnerId, currentStoreId };
}
