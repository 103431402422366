import { getMeFromStorage, Status, usePasswordlessAuthentication, useSession } from '@lendi/lala-react';
import { executeReturnURL, getBrandFromHostname, getReturnURLFromQuery, LalaSentry, Type } from '@lendi/lala-utils';
import { useEffect } from 'react';
import { getEnv } from '.';
import Router from '../helpers/router';
import { useActions } from '../state/actionProvider';
export const doRedirect = () => {
  const brand = getBrandFromHostname();
  let urlQueries = getReturnURLFromQuery();
  executeReturnURL(brand, Type.Customer, getEnv(), urlQueries);
};

export const useRedirect = () => {
  const { status } = useSession();
  const passwordlessAuth = usePasswordlessAuthentication();
  const { expireSession } = useActions();

  useEffect(() => {
    LalaSentry.logInfo('useRedirect hit on load', { status, otpStatus: passwordlessAuth?.status });
    if (status === Status.Authenticated && passwordlessAuth.status === undefined) {
      doRedirect();
    }

    if (status === Status.Expired) {
      const me = getMeFromStorage();
      expireSession(me?.firstName, me?.phone);
      // using location.search because at this point the Router.query() is empty
      Router.push(`/expired${location.search}`);
    }
  }, []);
};
