import { AuthOrigin } from '@lendi/core-constants';
import axios from 'axios';

import { SessionState } from '@lendi/lala-react';

export enum CustomerDetailsBrandEnum {
  Lendi = 'lendi',
  Aussie = 'aussie',
}

export interface CustomerDetails {
  id?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  authOrigin: AuthOrigin;
  emailVerified: boolean;
  mobileVerified: boolean;
  brand?: CustomerDetailsBrandEnum;
  utm: {
    utmCampaign: string;
    utmMedium: string;
  };
  email: string;
  mobileNumber?: string;
  ownerId?: string;
  storeId?: string;
  state?: {
    postcode?: 'PASSED' | 'NEEDED';
    emailVerification?: 'PASSED' | 'NEEDED';
  };
}

export const getCustomerDetails = (token: string | undefined): Promise<CustomerDetails> => {
  const baseURL = process.env.API_BASE_URL;
  return axios
    .get(`${baseURL}/v1/customers/me/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data.data);
};

export interface UnverifiedMobilePatchPayload {
  unverifiedMobile: string;
}

export const putUnverifiedPhone = (
  session: SessionState,
  dataToPatch: UnverifiedMobilePatchPayload
): Promise<CustomerDetails> => {
  const customerId = session.identity?.id;
  const baseURL = process.env.API_BASE_URL;
  return axios.put(
    `${baseURL}/v1/customers/${customerId}/unverified-mobile`,
    { ...dataToPatch },
    {
      headers: {
        Authorization: `Bearer ${session.token}`,
      },
    }
  );
};

export const getLinkedCustomerMobile = (session: SessionState): Promise<any> => {
  const baseURL = process.env.API_BASE_URL;
  return axios.get(`${baseURL}/v1/customers/me/linkable-customer/mobile`, {
    headers: {
      Authorization: `Bearer ${session.token}`,
    },
  });
};

export const verifyOTP = (otpCode: string, token: string | undefined) => {
  const baseURL = process.env.API_BASE_URL;
  return axios.post(
    `${baseURL}/v1/customers/me/link-otp`,
    { otp: otpCode },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getActivatedLead = (leadId: string): Promise<any> => {
  const baseURL = process.env.API_BASE_URL;
  return axios.get(`${baseURL}/v1/customers/external/leads/activated/${leadId}`);
};

export const mergeAccounts = (accessToken: string, fromAccountToken: string, toAccountToken: string) => {
  const baseURL = process.env.API_BASE_URL;
  return axios.post(
    `${baseURL}/v1/customers/me/merged`,
    { fromAccountToken, toAccountToken },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const removeLinkability = (accessToken: string) => {
  const baseURL = process.env.API_BASE_URL;
  return axios.delete(`${baseURL}/v1/customers/me/linkable`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const requestOwner = (
  accessToken: string,
  customerId: string,
  requestedOwnerId?: string,
  requestedStoreId?: string
): Promise<any> => {
  const baseURL = process.env.API_BASE_URL;
  return axios.put(
    `/v1/customers/${customerId}`,
    { requestedOwnerId, requestedStoreId },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: baseURL,
    }
  );
};

export const resolveOwner = (refCode: string): Promise<{ ownerId: string | undefined }> => {
  const baseURL = process.env.API_BASE_URL;
  return axios.get(`/v1/customers/external/refcodes/${refCode}`, { baseURL: baseURL }).then((res) => res.data || {});
};

export const setAdvocate = (accessToken: string, referralCode: string) => {
  const baseURL = process.env.API_BASE_URL;
  return axios.put(
    `/v1/customers/me/advocate/`,
    {
      referralCode,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: baseURL,
    }
  );
};

export const updateCustomerRecord = (
  accessToken: string,
  customerId: string,
  campaignName?: string,
  isCompanyGen?: boolean
): Promise<any> => {
  const baseURL = process.env.API_BASE_URL;

  const payload = {
    campaignName,
    ...(isCompanyGen === true && { selfGen: false }),
  };

  return axios.put(`/v1/customers/${customerId}`, payload, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    baseURL: baseURL,
  });
};
