import { LalaSentry } from '@lendi/lala-utils';
import axios from 'axios';

export declare type BrokerStatus =
  | 'Competent'
  | 'Compliance'
  | 'Graduate'
  | 'PIP'
  | 'Off Boarded'
  | 'Role Change'
  | 'Active'
  | 'Suspended'
  | null;

export declare type StoreStatus = 'ACTIVE' | 'INACTIVE';

export interface TeamMember {
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  profile?: string;
  profilePictureURL?: string;
  createdAt?: string;
  brokerStatus?: BrokerStatus;
}
export interface Store {
  id: string;
  name: string;
  storeStatus?: StoreStatus;
}

const getConfig = (authToken: string) => ({
  baseURL: process.env.API_BASE_URL,
  headers: {
    Authorization: `Bearer ${authToken}`,
    'Content-Type': 'application/json',
  },
});

export const getTeamMember = async (authToken: string, teamMemberId: string): Promise<TeamMember | null> => {
  try {
    const { data } = await axios.get(`/v1/team-member/${teamMemberId}`, getConfig(authToken));
    return data?.data;
  } catch (error) {
    LalaSentry.logInfo('Error getting team member', { error, teamMemberId });
    return null;
  }
};
export const getStore = async (authToken: string, storeId: string): Promise<Store | null> => {
  try {
    const { data } = await axios.get(`/v1/store/${storeId}`, getConfig(authToken));
    return data?.data;
  } catch (error) {
    LalaSentry.logInfo('Error getting store', { error, storeId });
    return null;
  }
};
