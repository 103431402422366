import { rules } from './rules';

export function findRule(
  currentStore: string | undefined,
  currentBroker: string | undefined,
  targetStore: string | undefined,
  targetBroker: string | undefined
) {
  const storeA = currentStore ? 1 : undefined;
  const brokerA = currentBroker ? 1 : undefined;
  const storeB = targetStore ? (targetStore == currentStore ? 1 : 2) : undefined;
  const brokerB = targetBroker ? (targetBroker == currentBroker ? 1 : 2) : undefined;

  const rule = rules.find(
    (r) =>
      r.input.storeA === storeA &&
      r.input.brokerA === brokerA &&
      r.input.storeB === storeB &&
      r.input.brokerB === brokerB
  );

  return {
    messageTemplate1: rule?.output.message1,
    messageTemplate2: rule?.output.message2,
    title: rule?.output.title,
    requestStoreId: rule?.output.store ? targetStore : undefined,
    requestOwnerId: rule?.output.broker ? targetBroker : undefined,
  };
}
